.pricing {
  width: 100%;
  padding: 6rem 1rem;
  background-color: #0f1c2f;
  align-items: center;
}

.card-container {
  max-width: 1280px;
  margin: auto;
  display: grid;
  grid-gap: 40px;
  grid-template-columns: repeat(3, 1fr);
}

.card-container .card {
  border: 1px solid #eee;
  color: #eee;
}

.card {
  text-align: center;
  padding: 1rem;
  /* flex: 0 0 100%;
  max-width: 100%;
  scroll-snap-align: center; */
}

.card:hover {
  background-color: #54595f;
}

.card h3 {
  font-size: 1.4rem;
  padding: 1rem;
  font-weight: 300;
}

.card .bar {
  border-bottom: 1px solid #eee;
  width: 10%;
  margin: 1.5rem auto;
  display: block;
}

.card .btc {
  font-size: 4rem;
  font-weight: 600;
  padding: 1rem;
}

.pricing p {
  color: #eee;
  padding: 1rem 0;
  font-size: 1.2rem;
}

.card .btn {
  /* margin: 2rem 0.2rem; */
  display: block;
  width: 80%;
  margin: 2rem auto;
  background-color: #0f1c2f;
  border: 2px solid #ff003f;
}

.card .btn:hover {
  background-color: #ff003f;
  border: #0f1c2f 1px solid;
  transition: 0.5s;
}

.sample-sites {
  text-align: center;
  padding: 3rem;
  font-size: 3rem;
  /* border: solid blue 1px; */
  width: 100%;
  margin: auto;
  margin-top: 40px;
}

.sample-sites h4 {
  padding-top: 2rem;
  text-decoration: underline;
  font-size: 2rem;
}

.sample-sites li a {
  text-align: center;
  font-size: 2rem;
  font-weight: 300;
  /* color: blue; */
}
.sample-sites li {
  padding: 1rem 0;
}
.thumbnail {
  width: 100%;
  align-items: center;
  justify-content: center;
  margin: auto;
  /* height: 150 px; */
}
.pageNumber {
  display: none;
}

@media screen and (max-width: 1280px) {
  .card {
    text-align: center;
    padding: 1rem;
    flex: 0 0 100%;
    max-width: 100%;
    scroll-snap-align: center;
  }
  .pageNumber {
    display: block;
  }
  .thumbnail {
    width: 90%;
    align-items: center;
    justify-content: center;
    margin: auto;
    /* height: 150 px; */
  }

  .thumbnails {
    display: flex;
    flex-wrap: wrap;
    /* gap: 20px; */
    width: 100%;
  }
  .card-container {
    max-width: 100%;
    margin: auto;
    grid-template-columns: 1fr;
    display: flex;
    overflow-x: auto;
    white-space: nowrap;
    scroll-snap-type: x mandatory;
  }
  .sample-sites h4 {
    padding: 2rem 0;
    text-decoration: none;
    font-size: 1.5rem;
  }

  .sample-sites li a {
    text-align: center;
    font-size: 1.4rem;
    color: white;
  }

  .sample-sites {
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 0rem;
    /* border: solid blue 1px; */
    width: 100%;
    margin: 0;
    margin-top: 40px;
  }
  .pricing {
    padding: 1rem 0.5rem;
  }
  .btc {
    font-size: 2.2rem !important;
  }
  .sample-sites li {
    padding: 0.8rem 0;
    width: 100%;
  }
}
