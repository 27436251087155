.header {
  position: fixed;
  width: 100%;
  height: 90px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  z-index: 10;
  background-color: transparent;
  transition: background-color 1s;
}

.header-bg {
  background-color: #0f1c2f;
  background-color: rgba(15, 28, 47, 0.85);
  transition: background-color 0.5s;
}

.nav-menu {
  display: flex;
}

.nav-menu li {
  padding: 0 1rem;
  font-weight: 500;
}

.nav-menu li::after {
  content: '';
  width: 0%;
  height: 2px;
  background: #ff003f;
  display: block;
  margin: auto;
  transition: 0.5s;
}
.nav-menu li:hover::after {
  width: 80%;
}

.nav-menu a {
  font-size: 1.1rem;
  font-weight: 500;
  padding: 0 0.5rem 0 0.5rem;
  font-weight: 700;
}

.hamburger {
  display: none;
  padding: 10.5px;
  padding-right: 20px;
}
.logo {
  width: 90px;
  margin-top: 4px;
  margin-left: 3.5rem;
}

@media screen and (max-width: 768px) {
  .hamburger {
    display: block;
    margin-right: 12px;
    text-decoration: none;
  }
  .header {
    justify-content: space-between;
    height: 60px;
    padding-left: 10px;
  }
  .nav-menu {
    position: absolute;
    flex-direction: column;
    align-items: center;
    top: 0;
    left: -100%;
    text-align: center;
    width: 100%;
    height: 100vh;
    transition: 0.3s;
    z-index: -1;
    background-color: rgba(0, 0, 0, 0.9);
    padding-top: 10rem;
  }

  .nav-menu.active {
    left: 0;
  }

  .nav-menu li {
    padding: 1rem 0;
    color: blue;
  }

  .nav-menu a {
    font-size: 1rem;
  }
  .logo {
    width: 60px;
    margin-top: 4px;
    margin-left: 4px;
  }
}
