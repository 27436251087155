.training {
  width: 100%;
  margin-top: 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  height: 100%;
  display: flex;
  position: relative;
  background-color: #0f1c2f;
  padding: 0;
}

.training p {
  /* font-size: 2.2rem; */
}

.training .left {
  text-align: center;
  margin: auto;
  padding: 2rem 1rem;
  /* max-width: 800px;
  width: 50%; */
}

.training .left p {
  margin: 1rem auto;
  padding: 1rem;
}

.training .right {
  width: 50%;
  padding: 10rem 1rem;
}

.right .img-container {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  position: relative;
  align-items: center;
  text-align: center;
}

.right .img {
  max-width: 60%;
  border: 1px solid #333;
}

.right .top {
  grid-column: 1 / span 8;
  grid-row: 1;
  padding-top: 20%;
  z-index: 6;
}

.right .bottom {
  grid-column: 4/ -1;
  grid-row: 1;
  z-index: 7;
}

.training a {
  display: flex;
  align-items: center;
  justify-content: center;
}

.training h1 {
  /* font-size: 2rem; */
}
#blurb {
  padding-bottom: 0px;
}
.btn-contain {
  padding-top: 2rem;
}
@media screen and (max-width: 1280px) {
  .training {
    grid-template-columns: 1fr;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .training .img {
    max-width: 100%;
  }
  .training .left p {
    /* font-size: 1.4rem; */
    margin: 0;
    padding: 2rem 0;
    width: 100%;
  }
  .training h1 {
    /* font-size: 1.8rem; */
  }
  .training .right,
  .training .left {
    width: 100%;
    padding: 3rem 1rem;
  }
  #jira {
    margin-bottom: 230px;
    padding-bottom: 0px;
    margin-left: 10px;
  }
  .top {
    /* width: 160%; */
    padding-right: 2rem;
    /* margin-top: 50px; */
  }
}
