.tpm-hero-img {
  background: rgba(0, 0, 0, 0.3);
  height: 100vh;
  width: 100%;
}

.tpm-hero-img:before {
  content: '';
  background: url('../assets/chris-montgomery-smgTvepind4-unsplash.jpg')
    no-repeat center center/cover;
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
}

.tpm-hero-img .heading {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.tpm-hero-img h1 {
  /* font-size: 2.4rem; */
  text-align: center;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 1rem;
  border-radius: 20px;
}

.tpm-hero-img p {
  /* font-size: 2rem; */
  padding: 1.8rem;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 1rem;
  border-radius: 20px;
}

@media screen and (max-width: 640px) {
  .tpm-hero-img h1 {
    /* font-size: 1.8rem; */
    padding: 0.8rem;
    width: fit-content;
  }
  .tpm-hero-img p {
    /* font-size: 1.4rem; */
  }
}
