.hero-contact-img {
  background: rgba(0, 0, 0, 0.3);
  height: 100vh;
  width: 100%;
  position: relative;
}

.hero-contact-img:before {
  content: '';
  background: url('../assets/hands-globe-earth.webp') no-repeat center top/cover;
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
}

.hero-contact-img .heading {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.hero-contact-img h1 {
  /* font-size: 2.4rem; */
  background-color: rgba(0, 0, 0, 0.5);
  padding: 1rem;
  border-radius: 20px;
}

.hero-contact-img p {
  /* font-size: 2rem; */
  padding: 1.8rem;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 1rem;
  border-radius: 20px;
}

@media screen and (max-width: 640px) {
  .hero-contact-img h1 {
    /* font-size: 2rem; */
  }
  .hero-contact-img p {
    /* font-size: 1.6rem; */
    text-align: center;
  }
}
