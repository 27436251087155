@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@300;400;500;600;700;800&family=Roboto:ital,wght@0,700;1,400&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

h1,
p,
a,
h4,
h2 {
  color: #fff;
  /* font-size: 1.6rem; */
  font-family: 'Ariel', 'Outfit', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-weight: 500;
}
h1,
h2 {
  font-size: 2rem;
}

p {
  font-size: 1.2rem;
}

.divider {
  width: 25rem;
  margin: 0 auto;
  border-bottom: 2px solid #fff;
  padding-top: 2rem;
  margin-bottom: 2rem;
}
.logo-full-img {
  width: 240px;
  height: 240px;
}
#slogan {
  font-weight: 500;
  font-size: 1.2rem;
  padding-bottom: 2.5rem;
}

body {
  margin: 0;
  font-family: 'Ariel', 'Outfit', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background: #0f1c2f;
}

ul {
  list-style-type: none;
}

a {
  text-decoration: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.btn {
  padding: 8px 16px;
  font-size: 1rem;
  text-transform: uppercase;
  background: transparent;
  color: #fff;
  border: 1px solid #fff;
  cursor: pointer;
  font-weight: 300;
}

/* .btn-light {
  background: rgba(255, 255, 255, 0.2);
} */

.btn:hover {
  background: blue;
  border: black solid 1px;
}
