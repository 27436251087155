#video {
  position: fixed;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}
.hero {
  height: 100vh;
  width: 100%;
  object-fit: contain;
}
.hero .content {
  text-align: center;
  position: absolute;
  margin: auto;
  width: 100%;
  height: 100%;
  top: 24vh;
}
.hero .content h1 {
  color: #fff;
}
.hero span {
  color: #fff;
  font-weight: 300;
}
.hero p {
  text-transform: uppercase;
  margin-bottom: 1.6rem;
  color: #fff;
  padding: 1rem 0;
}
.hero .btn {
  background-color: #0f1c2f;
  border: 2px solid #ff003f;
}
.hero .btn:hover {
  background-color: #ff003f;
  border: #0f1c2f 1px solid;
  transition: 0.5s;
}
@media screen and (max-width: 786px) {
  #video {
    display: none;
  }
  .hero {
    height: 100vh;
    background: url('../assets/anirudh-Xu4Pz7GI9JY-unsplash.jpg') no-repeat
      center bottom/cover;
  }
  .divider {
    width: 48%;
  }
}
