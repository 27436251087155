.full-stack {
  display: flex;
  height: 100%;
  position: relative;
  background-color: #0f1c2f;
  padding: 2rem;
  align-items: center;
  justify-content: center;
}

.full-stack p {
  /* font-size: 2.2rem; */
  margin: 1.2rem;
  padding: 2rem;
}

/* LEFT SIDE */
.full-stack-left {
  text-align: center;
  margin: auto;
  padding: 1rem 0rem;
  max-width: 50%;
}

.full-stack-image {
  padding-top: 100px;
}

#mern-img {
  border-radius: 20px;
}

/* .full-stack-left p {
  margin: 1.2rem;
  padding: 2rem;
} */

/* RIGHT SIDE */
.full-stack-right {
  margin: auto;
  padding: 1rem 0rem;
  max-width: 50%;
}

.full-stack-img {
  max-width: 80%;
  border-radius: 20px;
  /* margin: auto; */
  /* padding: 0 150px; */
  /* width: 840px; */
  /* justify-content: center; */
  /* align-items: center; */
}

/* @media screen and (max-width: 1280px) {
  .full-stack {
    flex-direction: column; */
/* display: grid;
    grid-template-columns: 1fr; */
/* flex-direction: column; */
/* } */
/* .full-stack-right .full-stack-left {
    flex-direction: column; */
/* } */
/* .full-stack {
    max-width: 80%;
  } */
@media screen and (max-width: 1280px) {
  .full-stack {
    /* grid-template-columns: 1fr; */
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  .full-stack-left {
    width: 100%;
    margin: 0;
    max-width: 100%;
  }

  .full-stack .btn {
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: auto;
    max-width: 100%;
  }
  .full-stack p {
    margin: 0.8rem;
    padding: 0.8rem;
    width: 100%;
    text-align: center;
    /* font-size: 1.4rem; */
  }

  .full-stack-img,
  #mern-img {
    max-width: 80%;
  }
}
