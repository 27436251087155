.hero-img {
  background: rgba(0, 0, 0, 0.3);
  height: 100vh;
  width: 100%;
  position: relative;
}

.hero-img:before {
  content: '';
  background: url('../assets/web-design.webp') no-repeat center bottom/cover;
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
}

.hero-img .heading {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.hero-img h1 {
  /* font-size: 2.4rem; */
  background-color: rgba(0, 0, 0, 0.5);
  padding: 0.6rem 1rem;
  border-radius: 20px;
}

.hero-img p {
  /* font-size: 2rem; */
  /* padding: 1.8rem; */
  background-color: rgba(0, 0, 0, 0.5);
  padding: 1rem;
  margin: 0 0rem;
  border-radius: 20px;
  max-width: fit-content;
  text-align: center;
  width: 50%;
}

@media screen and (max-width: 1280px) {
  .hero-img h1 {
    font-size: 1.8rem;
  }
  .hero-img p {
    font-size: 1.4rem;
    text-align: center;
    max-width: fit-content;
    width: 100%;
  }
}
