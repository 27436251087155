.fsd-hero-img {
  background: rgba(0, 0, 0, 0.3);
  height: 100vh;
  width: 100%;
}

.fsd-hero-img:before {
  content: '';
  /* background: url('../assets/Ongoing\ Web\ Design\,\ Hosting\,\ Maintenance\,\ and\ SEO.webp') */
  background: url('../assets/apps.jpg') no-repeat center bottom/cover;
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
}

.fsd-hero-img .heading {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* padding-bottom: 20rem; */
}

.fsd-hero-img h1 {
  /* font-size: 2.4rem; */
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 20px;
  padding: 1rem;
  text-align: center;
}

.fsd-hero-img p {
  /* font-size: 2rem; */
  padding: 1rem;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 20px;
}

@media screen and (max-width: 640px) {
  .fsd-hero-img h1 {
    /* font-size: 1.8rem; */
    padding: 0.8rem;
    width: 75%;
  }
  .fsd-hero-img p {
    /* font-size: 1.4rem; */
    text-align: center;
    padding: 0.8rem;
  }
  .fsd-hero-img .heading {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 24rem;
  }
}
