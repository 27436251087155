.footer {
  width: 100%;
  padding: 1rem 0;
  background-color: #0f1c2f;
  position: relative;
  left: 0;
  bottom: 0;
  right: 0;
  border-top: solid #6d9bc3 2px;
}

.tagline {
  /* font-size: 1rem; */
  font-weight: 300;
  font-size: 1rem;
}
.footer-container {
  max-width: 1280px;
  margin: auto;
  /* display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 40px; */
}

.footer .left {
  height: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  /* padding-right: 14rem; */
  margin: auto;
  text-align: center;
}

.footer .right {
  height: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  padding-left: 14rem;
}

.footer .right .social a {
  padding: 0 1.4rem;
}

.footer h4 {
  /* font-size: 1.4rem; */
  padding: 0.6rem 0;
  text-align: center;
  margin-bottom: -0.2rem;
}
#email {
  color: #6d9bc3;
  text-decoration: underline;
  padding: 0;
}

.footer a {
  font-size: 1rem;
  padding: 0rem 0;
  font-weight: 100;

  z-index: 1;
}

.footer .location p {
  padding-bottom: 0.5rem;
}

div .social {
  padding: 1rem 0;
}

#title {
  /* font-size: 2rem; */
  margin-top: 8px;
  padding-bottom: 0px;
}

#copyright {
  text-align: center;
  color: white;
  padding: 0.8rem 2rem;
  font-size: 1 rem;
  font-weight: 100;
}
.copyright-container {
  display: flex;
  width: 60%;
  align-items: center;
  margin: auto;
  flex-direction: row;
  justify-content: space-around;
}

.legal-big {
  text-align: center;
  color: #6d9bc3;
  padding: 0.8rem 2rem;
  font-size: 1rem;
  text-decoration: underline;
}
.legal-small {
  display: none;
}

.email {
  padding-left: 0px;
}
.location {
  margin-left: -30px;
}
.phone {
  font-size: 1rem;
  color: white;
  margin-top: 2rem;
}

@media screen and (max-width: 1280px) {
  .legal-big {
    display: none;
  }
  #email {
    color: #6d9bc3;
    text-decoration: underline;
    padding: 0;
    font-size: 1rem;
  }

  .legal-small {
    display: block;
    text-align: center;
    color: #6d9bc3;
    padding: 0.4rem 0;
    font-size: 1rem;
    text-decoration: underline;
  }
  #copyright {
    font-size: 0.8rem;
  }
  .phone {
    font-size: 1rem;
    color: white;
  }

  .footer-container {
    grid-template-columns: 1fr;
    /* margin-top: 100px; */
  }
  .footer .right,
  .footer .left {
    padding: 1rem;
  }
  .right .social {
    margin: auto;
  }
}
