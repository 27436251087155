.main {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  background-color: #0f1c2f;
  padding: 2rem;
  align-items: center;
  justify-content: center;
  padding: 20px;
  align-items: center;
}

.main p {
  /* font-size: 2.2rem; */
  margin: 1.2rem;
  padding: 2rem;
}
